<template>
  <v-container>
    <v-row justify="start">
      <v-col
        cols="12"
        md="8"
      >
        <v-list>
          <template
            v-for="project in projects"
            :key="project.slug"
          >
            <v-list-item class="align-center">
              <div class="ml-4">
                <v-list-item-title class="text-darkText">
                  <router-link :to="project.route">
                    {{ project.title }}
                  </router-link>
                </v-list-item-title>
                <v-list-item-subtitle class="text-darkText item-subtitle">
                  {{ project.description }}
                </v-list-item-subtitle>
              </div>
            </v-list-item>
            <v-divider class="my-4" />
          </template>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
  data() {
    return {
      projects: [
        {
          title: "Perfect Typing",
          description:
            "A typing game to help your WPM, perfecting word typing, and learning Wiki terms at the same time!",
          slug: "perfect-typing",
          route: "/projects/perfect-typing",
        },
      ],
    }
  },
})
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.v-list-item {
  margin-bottom: 10px;
}
.v-list-item-subtitle {
  color: #fff;
}
.item-subtitle {
  opacity: 1 !important;
}
.v-list-item-text {
  margin-top: 5px;
}
.date {
  color: #000;
  font-weight: bold;
}
.v-list-item-content {
  align-items: center;
}
.ml-4 {
  margin-left: 16px;
}
</style>
