<template>
  <v-container>
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <v-row class="text-darkText">
          Hey! I'm Gordon Cheng, a lifelong learner with a keen interest in
          software development and exploring life's intricacies. I like to
          experiment and test the boundaries of software. I also like video
          games, latest tech news, 3d-printing, archery, golf, stocks, and reality beyond.
        </v-row>

        <h2 class="text-h5 mt-4 mb-2">
          Technologies & Platforms
        </h2>
        <p>I work with a variety of technologies and platforms, including:</p>
        <!-- Tabs -->
        <v-tabs
          v-model="activeTab"
          class="mb-4"
        >
          <v-tab value="icons">
            Icon Grid
          </v-tab>
          <v-tab value="graph">
            3D Network
          </v-tab>
        </v-tabs>

        <!-- Tab Content -->
        <v-window v-model="activeTab">
          <!-- Icons Tab -->
          <v-window-item value="icons">
            <v-row class="mt-2">
              <v-col
                v-for="tech in technologies"
                :key="tech.name"
                cols="6"
                sm="4"
                md="3"
                lg="2"
              >
                <v-tooltip
                  :text="tech.name"
                  location="bottom"
                >
                  <template #activator="{ props }">
                    <div
                      v-bind="props"
                      class="tech-item"
                    >
                      <div class="tech-logo-wrapper">
                        <div class="tech-logo">
                          <SvgWrapper :svg="tech.logo" />
                        </div>
                      </div>
                      <div class="tech-name">
                        {{ tech.name }}
                      </div>
                    </div>
                  </template>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-window-item>

          <!-- 3D Graph Tab -->
          <v-window-item value="graph">
            <!-- Use v-show instead of v-if -->
            <div
              v-show="isContainerReady"
              ref="graphContainer"
              class="graph-container"
            />
            <div
              v-if="selectedNode"
              class="graph-details mt-4 pa-4 rounded-lg bg-grey-lighten-3"
            >
              <h3 class="text-h6">
                {{ selectedNode.name }}
              </h3>
              <p class="text-body-1">
                Category: {{ selectedNode.group }}
              </p>
            </div>
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import {
  ref,
  Component,
  markRaw,
  onUnmounted,
  onMounted,
  watch,
  nextTick,
} from "vue"

import ForceGraph3D, { ForceGraph3DInstance } from "3d-force-graph"
import * as THREE from "three"
import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js"
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry.js"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js"

import HelvetikerRegular from "@/assets/fonts/helvetiker_regular.typeface.json"

import SvgWrapper from "@/components/SvgWrapper.vue"
import { createRawSvgRenderer } from "@/utils/svgUtils"

import TypescriptIcon from "@/assets/icons/typescript.svg"
import JavascriptIcon from "@/assets/icons/javascript.svg"
import PythonIcon from "@/assets/icons/python.svg"
import KotlinIcon from "@/assets/icons/kotlin.svg"
import RustIcon from "@/assets/icons/rust.svg"
import CSharpIcon from "@/assets/icons/csharp.svg"
import CPlusPlusIcon from "@/assets/icons/cplusplus.svg"
import CIcon from "@/assets/icons/c.svg"

import ViteIconRaw from "@/assets/icons/vite.svg?raw"

import ReactIcon from "@/assets/icons/react.svg"
import ReduxIcon from "@/assets/icons/redux.svg"
import VueIcon from "@/assets/icons/vue.svg"
import NetlifyIcon from "@/assets/icons/netlify.svg"

import AWSIcon from "@/assets/icons/aws.svg"

import DockerIcon from "@/assets/icons/docker.svg"
import JenkinsIcon from "@/assets/icons/jenkins.svg"
import CircleCIIcon from "@/assets/icons/circleci.svg"

import GitIcon from "@/assets/icons/git.svg"

import ElectronJs from "@/assets/icons/electron.svg"
import FastApi from "@/assets/icons/fastapi.svg"
import Sqlite from "@/assets/icons/sqlite.svg"

import DotNet from "@/assets/icons/dotnet.svg"
import SqlServer from "@/assets/icons/sqlserver.svg"

import Atlassian from "@/assets/icons/atlassian.svg"

import PytestIcon from "@/assets/icons/pytest.svg"
import JestIcon from "@/assets/icons/jest.svg"
import CypressIconRaw from "@/assets/icons/cypress.svg?raw"

import MirageJs from "@/assets/icons/mirage.svg"
import PostmanIcon from "@/assets/icons/postman.svg"

interface Technology {
  name: string
  logo: Component
  category: TechCategoryKey
}

interface GraphNode {
  id: string
  name: string
  group: string
  val: number
  isGroupCenter?: boolean
  x?: number
  y?: number
  z?: number
  fx?: number
  fy?: number
  fz?: number
}

interface GraphLink {
  source: string
  target: string
  value?: number
}

interface GraphData {
  nodes: GraphNode[]
  links: GraphLink[]
}

const activeTab = ref<"icons" | "graph">("icons")
const graphContainer = ref<HTMLDivElement | null>(null)
const selectedNode = ref<GraphNode | null>(null)
const isContainerReady = ref<boolean>(false)
let graph: ForceGraph3DInstance | null = null
let animationFrameId: number | null = null
let camera: THREE.PerspectiveCamera
let orbitControls: OrbitControls
let resizeObserver: ResizeObserver

type TechCategoryKey =
  | "languages"
  | "frameworks"
  | "frontend"
  | "backend"
  | "devops"
  | "versionControl"
  | "saas"
  | "testing"
interface TechCategoryEntry {
  label: string
  color: string
}

// Organize technologies into categories
const TechCategory: Record<TechCategoryKey, TechCategoryEntry> = {
  languages: { label: "Languages", color: "#ff6b6b" },
  frameworks: { label: "Frameworks", color: "#f5a623" },
  frontend: { label: "Frontend", color: "#4ecdc4" },
  backend: { label: "Backend", color: "#45b7d1" },
  devops: { label: "DevOps", color: "#96ceb4" },
  versionControl: { label: "Version Control", color: "#ffcc00" },
  saas: { label: "SaaS", color: "#ff49f0" },
  testing: { label: "Testing", color: "#cc99ff" },
}

const technologies = ref<Technology[]>([
  {
    name: "TypeScript",
    logo: markRaw(TypescriptIcon as unknown as object),
    category: "languages",
  },
  {
    name: "JavaScript",
    logo: markRaw(JavascriptIcon as unknown as object),
    category: "languages",
  },
  {
    name: "Python",
    logo: markRaw(PythonIcon as unknown as object),
    category: "languages",
  },

  {
    name: "Kotlin",
    logo: markRaw(KotlinIcon as unknown as object),
    category: "languages",
  },
  {
    name: "C#",
    logo: markRaw(CSharpIcon as unknown as object),
    category: "languages",
  },
  {
    name: "Rust",
    logo: markRaw(RustIcon as unknown as object),
    category: "languages",
  },
  {
    name: "C++",
    logo: markRaw(CPlusPlusIcon as unknown as object),
    category: "languages",
  },
  {
    name: "C",
    logo: markRaw(CIcon as unknown as object),
    category: "languages",
  },

  {
    name: "Vite",
    logo: markRaw(createRawSvgRenderer(ViteIconRaw) as unknown as object),
    category: "frontend",
  },
  {
    name: "ReactJS and React Native",
    logo: markRaw(ReactIcon as unknown as object),
    category: "frameworks",
  },
  {
    name: "Redux",
    logo: markRaw(ReduxIcon as unknown as object),
    category: "frameworks",
  },
  {
    name: "VueJS",
    logo: markRaw(VueIcon as unknown as object),
    category: "frameworks",
  },
  {
    name: "Netlify",
    logo: markRaw(NetlifyIcon as unknown as object),
    category: "devops",
  },

  {
    name: "AWS",
    logo: markRaw(AWSIcon as unknown as object),
    category: "backend",
  },

  {
    name: "Docker",
    logo: markRaw(DockerIcon as unknown as object),
    category: "devops",
  },
  {
    name: "Jenkins",
    logo: markRaw(JenkinsIcon as unknown as object),
    category: "devops",
  },
  {
    name: "CircleCI",
    logo: markRaw(CircleCIIcon as unknown as object),
    category: "devops",
  },
  {
    name: "Git",
    logo: markRaw(GitIcon as unknown as object),
    category: "versionControl",
  },

  {
    name: "ElectronJS",
    logo: markRaw(ElectronJs as unknown as object),
    category: "frameworks",
  },
  {
    name: "FastAPI",
    logo: markRaw(FastApi as unknown as object),
    category: "backend",
  },
  {
    name: "SQLite",
    logo: markRaw(Sqlite as unknown as object),
    category: "backend",
  },

  {
    name: ".Net Framework",
    logo: markRaw(DotNet as unknown as object),
    category: "frameworks",
  },
  {
    name: "T-SQL and SQL Server",
    logo: markRaw(SqlServer as unknown as object),
    category: "backend",
  },
  {
    name: "Atlassian",
    logo: markRaw(Atlassian as unknown as object),
    category: "saas",
  },
  {
    name: "Pytest",
    logo: markRaw(PytestIcon as unknown as object),
    category: "testing",
  },
  {
    name: "Jest",
    logo: markRaw(JestIcon as unknown as object),
    category: "testing",
  },
  {
    name: "Cypress",
    logo: markRaw(createRawSvgRenderer(CypressIconRaw) as unknown as object),
    category: "testing",
  },
  {
    name: "MSTest",
    logo: markRaw(DotNet as unknown as object),
    category: "testing",
  },

  {
    name: "MirageJS",
    logo: markRaw(MirageJs as unknown as object),
    category: "testing",
  },
  {
    name: "Postman",
    logo: markRaw(PostmanIcon as unknown as object),
    category: "testing",
  },
])

// Calculate the node position, great for showing the text relative to the center node.
const calculateNodePosition = (node: GraphNode, graphData: GraphData) => {
  // Find the center node for this group
  const centerNodeId = `${node.group}-center`
  const centerNode = graphData.nodes.find((n) => n.id === centerNodeId)

  if (!centerNode || centerNode.x == null || centerNode.y == null) {
    return { x: 0, y: 0 } // Fallback to default
  }

  // Calculate the relative position
  const dx = (node.x || 0) - centerNode.x
  const dy = (node.y || 0) - centerNode.y

  const distance = Math.sqrt(dx * dx + dy * dy) || 1 // Avoid zero distance
  const dirX = dx / distance
  const dirY = dy / distance

  // Apply scaled offset
  const textOffset = -0.5 // Base offset
  const scaleFactor = 0.2
  const scaledOffset = textOffset * (1 + distance * scaleFactor)

  return {
    x: dirX * scaledOffset,
    y: dirY * scaledOffset,
  }
}

// Create graph data from technologies
const createGraphData = () => {
  const nodes: GraphNode[] = []
  const links: GraphLink[] = []

  // Create center nodes for each category
  Object.entries(TechCategory).forEach(([key, category]) => {
    nodes.push({
      id: `${key}-center`,
      name: category.label,
      group: key,
      val: 0.0001, // Shrink node size
      isGroupCenter: true,
    })
  })

  // Add circular links between major categories
  const categoryKeys = Object.keys(TechCategory) as TechCategoryKey[]
  categoryKeys.forEach((key, index) => {
    const nextKey = categoryKeys[(index + 1) % categoryKeys.length]
    links.push({
      source: `${key}-center`,
      target: `${nextKey}-center`,
      value: 1,
    })
  })

  // Add technology nodes and connect them to their category centers
  technologies.value.forEach((tech) => {
    nodes.push({
      id: tech.name,
      name: tech.name,
      group: tech.category, // directly using the category from the tech object
      val: 0.0001, // shrink the nodes sizes to near 0, 0 doesn't work
    })

    links.push({
      source: `${tech.category}-center`, // using tech.category directly
      target: tech.name,
      value: 1,
    })
  })

  return { nodes, links }
}

const graphData: GraphData = createGraphData()

// Initialize graph when tab changes to graph view
onMounted(() => {
  if (graphContainer.value) {
    const resizeObserver = new ResizeObserver(() => {
      if (graph && graphContainer.value) {
        graph
          .width(graphContainer.value.clientWidth)
          .height(graphContainer.value.clientHeight)
      }
    })

    resizeObserver.observe(graphContainer.value)

    // Cleanup observer on unmount
    onUnmounted(() => {
      resizeObserver.disconnect()
    })
  }
})

// Function to create 3D text nodes only
const createNodeObject = (node: object): THREE.Object3D => {
  const typedNode = node as GraphNode
  const group = new THREE.Group()

  // Parse the raw font object into a Font instance
  const font = new FontLoader().parse(HelvetikerRegular)

  // Create 3D text for nodes
  const textGeometry = new TextGeometry(typedNode.name, {
    font: font,
    size: typedNode.isGroupCenter ? 16 : 5, // Larger text for group centers
    depth: typedNode.isGroupCenter ? 3 : 0.5,
    curveSegments: 12,
    bevelEnabled: true, // Enable beveling for the outline effect
    bevelThickness: 0.1,
    bevelSize: 0.05,
    bevelOffset: 0,
    bevelSegments: 5,
  })

  // Create two materials: one for the text face and one for the outline
  const materials: THREE.MeshLambertMaterial[] = [
    // Main face material
    new THREE.MeshLambertMaterial({
      color:
        TechCategory[typedNode.group as TechCategoryKey]?.color || "#ffffff",
    }),
    // Outline material (applied to the bevel and sides)
    new THREE.MeshLambertMaterial({
      color: "#000000",
    }),
  ]

  const textMesh = new THREE.Mesh(textGeometry, materials)

  // Center the text
  textGeometry.computeBoundingBox()
  const textWidth =
    textGeometry.boundingBox!.max.x - textGeometry.boundingBox!.min.x
  const textHeight =
    textGeometry.boundingBox!.max.y - textGeometry.boundingBox!.min.y

  if (typedNode.isGroupCenter) {
    // Center nodes are always centered
    textMesh.position.x = -textWidth / 2
    textMesh.position.y = -textHeight / 2
  } else {
    // Calculate dynamic position based on relationship to center
    const { x, y } = calculateNodePosition(typedNode, graphData)
    textMesh.position.x = -textWidth / 2 + x
    textMesh.position.y = -textHeight / 2 + y
    // textMesh.position.z += 5;
  }

  group.add(textMesh)

  return group
}

// Initialize graph function
const initGraph = () => {
  if (!graphContainer.value) return

  const boundingBox = new THREE.Box3(
    new THREE.Vector3(-50, -50, -50),
    new THREE.Vector3(50, 50, 50)
  )

  // Uncomment for debugging boundingBox
  // const boxHelper = new THREE.Box3Helper(boundingBox, 0xff0000);

  const forceGraph = new ForceGraph3D(
    graphContainer.value,
    // This setting allows the orbit controls to be enabled, which allows the angle restrictions like min and max polar and Azimuth
    { controlType: "orbit" }
  )

  // Uncomment for debugging boundingBox
  // forceGraph.scene().add(boxHelper);

  // Create custom PerspectiveCamera
  camera = new THREE.PerspectiveCamera(
    75, // Field of view
    graphContainer.value.clientWidth / graphContainer.value.clientHeight, // Aspect ratio
    0.1, // Near plane
    1000 // Far plane
  )

  // Set initial camera position
  camera.position.set(30, 0, 30)
  camera.lookAt(0, 0, 0)

  // Configure forces
  const linkForce = forceGraph.d3Force("link")
  if (linkForce) {
    linkForce.distance((link: any) => {
      // Stronger force for major category links
      if (
        link.source.id.includes("-center") &&
        link.target.id.includes("-center")
      ) {
        return 150
      }
      return 50 // Standard distance for others
    })
  }

  // Configure charge force (repulsion/attraction between all nodes)
  const chargeForce = forceGraph.d3Force("charge")
  if (chargeForce) {
    chargeForce.strength(
      (node: GraphNode) => (node.isGroupCenter ? -150 : -50) // Stronger attraction for center nodes
    )
    chargeForce.distanceMax(100)
  }

  // Configure center force (pulls everything to center of visualization)
  const centerForce = forceGraph.d3Force("center")
  if (centerForce) {
    centerForce.strength(1.0) // Moderate pull to center
  }

  // Add lighting for text visibility
  const light = new THREE.DirectionalLight(0xffffff, 1)
  light.position.set(0, 0, 1)
  forceGraph.scene().add(light)

  const ambientLight = new THREE.AmbientLight(0x404040, 1.5)
  forceGraph.scene().add(ambientLight)

  const graphControls = forceGraph.controls() as any

  graphControls.panSpeed = 2 // Update pan speed, default of 1 was too slow
  graphControls.minDistance = 100 // Minimum distance graph can be zoomed in
  graphControls.maxDistance = 400 // Maximum distance graph can be zoomed out
  graphControls.maxPolarAngle = Math.PI / 2 + Math.PI / 6 // 30 degrees below horizontal
  graphControls.minPolarAngle = Math.PI / 6 // 30 degrees from vertical
  graphControls.maxAzimuthAngle = Math.PI / 4 // 45 degress from right
  graphControls.minAzimuthAngle = -Math.PI / 4 // 45 degress from left

  graphControls.addEventListener("change", () => {
    const innerControls = forceGraph.controls() as any

    // Clamp the target within the bounding box, which restricts panning movement
    boundingBox.clampPoint(innerControls.target, innerControls.target)

    // Debug controls
    // console.log("controls", innerControls)
  })

  // Set up graph with text-only nodes
  graph = forceGraph
    .graphData(graphData)
    .nodeLabel("name")
    .nodeThreeObject(createNodeObject) // Use the updated node creation function
    .nodeThreeObjectExtend(true)
    .linkWidth((link: GraphLink) => {
      if (link.source.includes("-center") && link.target.includes("-center")) {
        return 0.001 // Near Invisible
      }
      return 1 // Standard width for other links
    })
    .linkOpacity((link: GraphLink) => {
      // Set category links to fully transparent
      if (link.source.includes("-center") && link.target.includes("-center")) {
        return 0.01 // Fully transparent
      }
      return 0.8 // Default opacity for other links
    })
    .linkColor((link: GraphLink) => {
      // Set category links to a neutral color (optional)
      if (link.source.includes("-center") && link.target.includes("-center")) {
        return "#FFFFFF" // White
      }
      const sourceNode = graphData.nodes.find((n) => n.id === link.source)
      const category = sourceNode?.group as TechCategoryKey
      return category ? TechCategory[category]?.color || "#ccc" : "#ccc"
    })
    .backgroundColor("#b2bac7")
    .width(graphContainer.value.clientWidth)
    .height(600)
    .cooldownTime(3000) // Increase cooldown time to allow forces to settle

  // Set up OrbitControls
  orbitControls = new OrbitControls(camera, graphContainer.value)
  orbitControls.enabled = true

  // Add mouse events to handle control states
  graphContainer.value.addEventListener("mousedown", () => {
    orbitControls.enabled = true
  })

  graphContainer.value.addEventListener("mouseup", () => {
    orbitControls.enabled = false
  })

  graphContainer.value.addEventListener("mouseleave", () => {
    orbitControls.enabled = false
  })

  // Update camera aspect ratio on container resize
  resizeObserver = new ResizeObserver(() => {
    if (graphContainer.value) {
      camera.aspect =
        graphContainer.value.clientWidth / graphContainer.value.clientHeight
      camera.updateProjectionMatrix()
      forceGraph.width(graphContainer.value.clientWidth)
      forceGraph.height(graphContainer.value.clientHeight)
    }
  })

  resizeObserver.observe(graphContainer.value)
}

// Clean up
onUnmounted(() => {
  if (graphContainer.value) {
    graphContainer.value.removeEventListener("mousedown", () => {})
    graphContainer.value.removeEventListener("mouseup", () => {})
    graphContainer.value.removeEventListener("mouseleave", () => {})
  }

  if (animationFrameId) {
    cancelAnimationFrame(animationFrameId)
    animationFrameId = null
  }

  if (orbitControls) {
    orbitControls.dispose()
  }

  if (resizeObserver) {
    resizeObserver.disconnect()
  }

  if (graph) {
    graph._destructor()
    graph = null
  }
})

watch(
  [activeTab, () => graphContainer.value],
  ([newTab, container]) => {
    if (newTab === "graph" && container && !graph) {
      // Mark container as ready
      isContainerReady.value = true
      // Use nextTick to ensure DOM is updated
      nextTick(() => {
        // Add a small delay to ensure container is properly sized
        setTimeout(() => {
          if (!graph && container) {
            initGraph()
          }
        }, 100)
      })
    }
  },
  { immediate: true } // This makes it run on component mount
)
</script>

<style scoped>
.tech-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 85px;
}

.tech-logo-wrapper {
  width: 48px;
  height: 48px;
  margin-bottom: 8px;
}

.tech-logo {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.svg-wrapper {
  width: 100%;
  height: 100%;
}

.tech-name {
  font-size: 12px;
  line-height: 1.2;
  max-width: 100%;
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.graph-container {
  position: relative;
  height: 600px;
  border-radius: 8px;
}

.graph-details {
  border: 1px solid #eee;
}
</style>
