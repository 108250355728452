<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <div
      v-if="wikiLoaded"
      class="typing-game-intro-message"
    >
      <p>
        Try this typing game out, let's see how well you can type, one mistake
        in a word and you'll have to retype that word. A random Wikipedia page
        summary is generated each time.
      </p>
      <!-- The link to the Wikipedia page -->
      <div
        v-if="wikiPageURL"
        class="text-center my-4"
      >
        <a
          :href="wikiPageURL"
          target="_blank"
        >Read the full Wikipedia article on
          <strong>{{ wikiPageTitle }}</strong></a>
      </div>
    </div>
  </transition>
  <transition
    name="fade"
    mode="out-in"
  >
    <div
      v-if="wikiLoaded"
      class="typing-game-settings-bar h-6"
    >
      <div class="typing-game-wpm">
        Words Per Minute:
        <strong>{{ formattedWordsPerMinute }}</strong> Mistyped Words:
        <strong>{{ mistypedWordCounter }}</strong> Time Elapsed:
        <strong>{{ Math.floor((elapsedTime / 1000 / 60) % 60) }}:{{
          ((elapsedTime / 1000) % 60).toFixed(0).padStart(2, "0")
        }}</strong>
      </div>
      <a
        class="typing-game-settings-button"
        href="#"
        @click.prevent="showMenu = !showMenu"
      >
        <CogIcon class="typing-game-settings-icon" />
      </a>
      <transition
        name="fade"
        mode="out-in"
      >
        <div
          v-show="showMenu"
          class="typing-game-settings-menu p-3 bg-gray-300 z-20"
        >
          <TypingGameSettings
            @capitals="checkCapOption"
            @punct="checkPunctOption"
            @mute="applyOptionMute"
          />
        </div>
      </transition>
    </div>
  </transition>
  <transition
    name="fade"
    mode="out-in"
  >
    <div
      v-if="wikiLoaded"
      id="windowID"
      class="typing-game-window"
      @keypress="select($event)"
    >
      <div
        v-for="(wordValue, wordIndex) in currentTokenizedLetters"
        :id="wordIndex.toString()"
        :key="wordIndex"
        class="word-container"
      >
        <span
          v-for="(letterValue, letterIndex) in wordValue"
          :key="wordIndex + ',' + letterIndex"
          :class="[
            'base-letter-format',
            letterIDsTypedDict[wordIndex + ',' + letterIndex]
              ? letterIDsTypedDict[wordIndex + ',' + letterIndex]
              : '',
            wordIndex + ',' + letterIndex == currentLetterID
              ? activeLetterClass
              : '',
          ]"
        >
          {{ letterValue }}
        </span>
        <span
          v-if="wordIndex < currentTokenizedLetters.length - 1"
          :key="'s' + wordIndex"
          :class="[
            'base-letter-format',
            letterIDsTypedDict['s' + wordIndex]
              ? letterIDsTypedDict['s' + wordIndex]
              : '',
            's' + wordIndex == currentLetterID ? activeLetterClass : '',
          ]"
        >
          &nbsp;
        </span>
      </div>
    </div>
  </transition>

  <div
    v-if="wikiLoaded && completedExtract"
    class="typing-game-completed"
  >
    <div class="typing-game-intro-message">
      You did it! You can retry the same passage by clicking on Restart or a new
      passsage by clicking on Another.
    </div>
  </div>

  <transition name="fade">
    <div
      v-if="wikiLoaded"
      class="typing-game-selections"
    >
      <v-row class="justify-center">
        <v-col cols="auto">
          <v-btn
            class="typing-game-button"
            elevation="2"
            @click="restart"
          >
            Restart (Shift + Space)
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            class="typing-game-button"
            elevation="2"
            @click="another"
          >
            Another (Shift + Enter)
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </transition>

  <audio
    ref="myAudio"
    autoplay
    loop
    muted
  >
    <source
      src="@/assets/audio/initiald-runninginthe90scut.mp3"
      type="audio/mp3"
    >
  </audio>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, reactive, provide, toRefs } from "vue"
import unidecode from "unidecode"
import onKeyDown from "@/assets/scripts/onKeyDown"
import CogIcon from "@/components/cogicon.vue"
import TypingGameSettings from "@/components/TypingGameSettings.vue"
import router from "@/router"
import { useRoute } from "vue-router"
import { watch } from "vue"

const props = defineProps<{
  title: string
}>()

const route = useRoute()
const { title } = toRefs(props)

const localTitle = ref<string>((route.params.title as string) || title.value)

watch(route.params, (newParams) => {
  if (newParams.title) {
    localTitle.value = newParams.title as string
  }
})

const wikiLoaded = ref<boolean>(false)
const wikiPageURL = ref<string>("")
const wikiPageTitle = ref<string>("")
const currentTokenizedLetters = ref<string[]>([])
const currentLetterID = ref<string>("0,0")
const letterIDsTypedDict = ref<any>({})
const currentMistypedWord = ref<any>({})
const activeLetterClass = ref<string>("active-letter")
const completedExtract = ref<boolean>(false)

const timer = ref<any>(null)
const elapsedTime = ref<number>(0)
const mistypedElapsedTime = ref<number>(0)
const mistypedLetterCounter = ref<number>(0)
const wordsCompleted = ref<number>(0)
const mistypedWordCounter = ref<number>(0)

const isMute = ref<boolean>(false)
const myAudio = ref<any>(null)
const backgroundAudioState = ref<string>("paused")

const showMenu = ref<boolean>(false)

const applyTokenization = (passage: string) => {
  const returnArray = []
  const tokenizedWords = JSON.parse(JSON.stringify(passage.split(" ")))
  for (let i = 0; i < tokenizedWords.length; ++i) {
    const tempWord = tokenizedWords[i].split("")
    returnArray.push(tempWord)
  }
  return returnArray
}

//Create a Store to contain the states
const store = {
  debug: false,
  state: reactive({
    displayLetters: new Map<number, string>(),
    stateNumber: 0, //State 0 is inital state
    isRemoveCap: false,
    isRemovePunct: false,
    isReset: false,
  }),
  setDisplayLetters(original: string) {
    if (this.state.isReset) {
      const newValue = this.state.displayLetters.get(0)!
      this.state.displayLetters.set(1, newValue)
      this.state.displayLetters.set(2, newValue.toLowerCase())
      this.state.displayLetters.set(
        3,
        newValue
          .replace(/[^A-Za-z0-9\s]/g, "")
          .replace("  ", " ")
          .replace("  ", " ")
      )
      this.state.displayLetters.set(
        4,
        newValue
          .toLowerCase()
          .replace(/[^A-Za-z0-9\s]/g, "")
          .replace("  ", " ")
          .replace("  ", " ")
      )
      this.state.isReset = false
    } else {
      this.state.displayLetters.set(0, original)
      this.state.displayLetters.set(1, original)
      this.state.displayLetters.set(2, original.toLowerCase())
      this.state.displayLetters.set(
        3,
        original
          .replace(/[^A-Za-z0-9\s]/g, "")
          .replace("  ", " ")
          .replace("  ", " ")
      )
      this.state.displayLetters.set(
        4,
        original
          .toLowerCase()
          .replace(/[^A-Za-z0-9\s]/g, "")
          .replace("  ", " ")
          .replace("  ", " ")
      )
    }
    if (this.debug) {
      console.log("setDisplayLetters to:", this.state.displayLetters)
    }
  },
  getDisplayLetters() {
    if (this.debug) {
      console.log(
        "getDisplayLetters is:" +
          this.state.displayLetters.get(this.state.stateNumber) +
          " at state:" +
          this.state.stateNumber
      )
    }
    return this.state.displayLetters.get(this.state.stateNumber)
  },
  setStateNumber() {
    if (this.state.isRemoveCap && this.state.isRemovePunct)
      this.state.stateNumber = 4
    else if (this.state.isRemovePunct) this.state.stateNumber = 3
    else if (this.state.isRemoveCap) this.state.stateNumber = 2
    else this.state.stateNumber = 1

    if (this.debug) {
      console.log("setStateNumber to:", this.state.stateNumber)
    }
  },
  setIsRemoveCap(newValue: boolean) {
    this.state.isRemoveCap = newValue
  },
  setIsRemovePunct(newValue: boolean) {
    this.state.isRemovePunct = newValue
  },
  setIsReset(newValue: boolean) {
    this.state.isReset = newValue
  },
}

provide("store", store)

const formattedWordsPerMinute = computed(() => {
  return Number.isFinite(
    Math.round(wordsCompleted.value / (elapsedTime.value / 1000 / 60)) || 0
  )
    ? Math.round(wordsCompleted.value / (elapsedTime.value / 1000 / 60)) || 0
    : 0
})

const fetchWikiData = async () => {
  let apiUrl = localTitle.value
    ? `https://en.wikipedia.org/api/rest_v1/page/summary/${title.value}`
    : "https://en.wikipedia.org/api/rest_v1/page/random/summary"

  const response = await fetch(apiUrl)
  const body = await response.json()
  return {
    url: body.content_urls.desktop.page,
    title: body.title,
    extract: unidecode(body.extract)
      .replace("\u000A", " ")
      .replace("  ", " ")
      .replace("  ", " ")
      .trim(),
  }
}

const getWikiExtract = async () => {
  const data = await fetchWikiData()

  wikiPageURL.value = data.url
  wikiPageTitle.value = data.title

  store.setDisplayLetters(data.extract)
  store.setStateNumber()

  currentTokenizedLetters.value.splice(
    0,
    currentTokenizedLetters.value.length,
    ...applyTokenization(store.getDisplayLetters()!)
  )

  if (!localTitle.value) {
    const urlParts = data.url.split("/")
    const routeAppendix = urlParts[urlParts.length - 1]
    router.push(`/projects/perfect-typing/${routeAppendix}`)
  }
  wikiLoaded.value = true
}

const playBGMusic = () => {
  let actualVolumeFadeOut = 0.03 //Volume set to the audio player from 0 to 1
  let actualVolumeFadeIn = 0

  //console.log(formattedWordsPerMinute.value)
  //console.log(myAudio.value.volume)
  //console.log(myAudio.value.muted)
  // If WordsPerMinute (WPM) is greater than 90 WPM
  myAudio.value.muted = isMute.value
  if (formattedWordsPerMinute.value > 90) {
    if (backgroundAudioState.value === "paused") {
      backgroundAudioState.value = "playing"

      const fadeInInterval = setInterval(() => {
        actualVolumeFadeIn = parseFloat((actualVolumeFadeIn + 0.01).toFixed(2))
        if (actualVolumeFadeIn <= actualVolumeFadeOut) {
          myAudio.value.volume = actualVolumeFadeIn.toString()
        } else if (actualVolumeFadeIn > actualVolumeFadeOut) {
          clearInterval(fadeInInterval)
        }
      }, 100)
      return false
    }
  }
  // If WordsPerMinute (WPM) is less than 90 WPM
  else {
    if (backgroundAudioState.value === "playing") {
      backgroundAudioState.value = "paused"
      const fadeOutInterval = setInterval(() => {
        actualVolumeFadeOut = parseFloat(
          (actualVolumeFadeOut - 0.01).toFixed(2)
        )
        if (actualVolumeFadeOut >= actualVolumeFadeIn) {
          myAudio.value.volume = actualVolumeFadeOut.toString()
        } else if (actualVolumeFadeOut < actualVolumeFadeIn) {
          clearInterval(fadeOutInterval)
        }
      }, 100)
      return false
    }
  }
}

const startTimer = () => {
  timer.value = setInterval(() => {
    elapsedTime.value += 1000
    mistypedElapsedTime.value += 1000
  }, 1000)
}
const stopTimer = () => {
  clearInterval(timer.value)
}
const resetTimer = () => {
  elapsedTime.value = 0
}

const resetMistypedTimerandCounter = () => {
  mistypedElapsedTime.value = 0
  mistypedLetterCounter.value = 0
}

const hideMenu = () => {
  showMenu.value = false
}

const restart = () => {
  currentLetterID.value = "0,0"
  letterIDsTypedDict.value = {}
  currentMistypedWord.value = {}
  wordsCompleted.value = 0
  completedExtract.value = false
  store.setIsReset(true)
  store.setDisplayLetters("")
  currentTokenizedLetters.value.splice(
    0,
    currentTokenizedLetters.value.length,
    ...applyTokenization(store.getDisplayLetters()!)
  )
  resetMistypedTimerandCounter()
  stopTimer()
  resetTimer()
}

const another = async () => {
  wikiLoaded.value = false
  restart()
  localTitle.value = "" // Reset the title prop
  await getWikiExtract() // Fetch new extract and update the state
  // getWikiExtract();
  currentTokenizedLetters.value.splice(
    0,
    currentTokenizedLetters.value.length,
    ...applyTokenization(store.getDisplayLetters()!)
  )
}

const checkCapOption = (param: boolean) => {
  store.setIsRemoveCap(param)
  store.setStateNumber()
  restart()
  currentTokenizedLetters.value.splice(
    0,
    currentTokenizedLetters.value.length,
    ...applyTokenization(store.getDisplayLetters()!)
  )
}

const checkPunctOption = (param: boolean) => {
  store.setIsRemovePunct(param)
  store.setStateNumber()
  restart()
  currentTokenizedLetters.value.splice(
    0,
    currentTokenizedLetters.value.length,
    ...applyTokenization(store.getDisplayLetters()!)
  )
}

const applyOptionMute = (param: boolean) => {
  isMute.value = param
  myAudio.value.muted = isMute.value
  //console.log("APPLIED MUTE? " + isMute.value);
}

onMounted(() => {
  store.state.isRemoveCap = localStorage.getItem("optionCapitals") === "true"
  store.state.isRemovePunct =
    localStorage.getItem("optionPunctuation") === "true"
  myAudio.value.volume = "0"
  isMute.value = localStorage.getItem("optionMute") === "true"

  getWikiExtract()
})

const select = (_: Event) => {
  // Your implementation here
}

onKeyDown((event: any) => {
  /*Shift + Spacebar to Restart*/
  if (event.shiftKey && event.key === " ") {
    restart()
    return
  }
  /*Shift + Enter for another passage*/
  if (event.shiftKey && event.key === "Enter") {
    another()
    return
  }
  //console.log(mistypedLetterCounter.value)
  if (currentLetterID.value === "0,0" && mistypedLetterCounter.value === 0) {
    stopTimer()
    resetTimer()
    startTimer()
  }

  playBGMusic()
  hideMenu()

  const keyPressed = String.fromCharCode(event.keyCode)
  //console.log("keycode: " + event.keyCode);
  //console.log("keypress: " + keyPressed);
  //console.log("currentLetterID: " + currentLetterID.value);

  //extract letter coordinates from array
  const coordinateString = currentLetterID.value.split(",")
  const wordCoordinate: any = coordinateString[0]
  const letterCoordinate: any = coordinateString[1]
  const spaceCoordinate = currentLetterID.value.substring(
    1,
    currentLetterID.value.length
  )

  //If current leter is a space
  if (currentLetterID.value.substring(0, 1) === "s") {
    if (keyPressed === " ") {
      resetMistypedTimerandCounter()
      letterIDsTypedDict.value[currentLetterID.value] = "letter-correct"
      //console.log("Space Matched! "+ currentLetterID.value);
    } else {
      letterIDsTypedDict.value[currentLetterID.value] = "letter-incorrect"
      //console.log("Space NOT MATCHED :( " + currentLetterID.value);
    }
  }
  //If current leter is a character
  else {
    if (
      keyPressed ===
      currentTokenizedLetters.value[wordCoordinate][letterCoordinate]
    ) {
      if (mistypedLetterCounter.value > 0) {
        letterIDsTypedDict.value[currentLetterID.value] = "letter-retyped"
      } else {
        letterIDsTypedDict.value[currentLetterID.value] = "letter-correct"
      }
      resetMistypedTimerandCounter()
      //console.log("Character Matched! "+ currentLetterID.value);
    } else {
      //If 1 letter is incorrect in the word, append that word after the current typed word.
      if (!currentMistypedWord.value[wordCoordinate]) {
        currentTokenizedLetters.value.splice(
          wordCoordinate,
          0,
          currentTokenizedLetters.value[wordCoordinate]
        )
        currentMistypedWord.value[wordCoordinate] = true
        mistypedWordCounter.value++ // Increment the mistyped word counter here
      }
      //increment counter for mistyped characters.
      mistypedLetterCounter.value++
      //Highlight character as red.
      letterIDsTypedDict.value[currentLetterID.value] = "letter-incorrect"
      //Since a letter is mistyped, keep the currentLetterID as the same character, by returning early
      //console.log("Character NOT MATCHED :( " + currentLetterID.value);
      return
    }
  }

  if (!completedExtract.value) {
    if (mistypedElapsedTime.value > 3000) {
      //If a letter is mistyped keep the currentLetterID as the start of the word.
      //currentLetterID.value = wordCoordinate + ",0"
      //console.log("mistyped!:" + currentLetterID.value);
    } else if (currentLetterID.value.substring(0, 1) === "s") {
      currentLetterID.value = parseInt(spaceCoordinate) + 1 + ",0"
    } else if (
      letterCoordinate >=
      currentTokenizedLetters.value[wordCoordinate].length - 1
    ) {
      currentLetterID.value = "s" + parseInt(wordCoordinate)
      wordsCompleted.value++
    } else {
      currentLetterID.value =
        wordCoordinate + "," + (parseInt(letterCoordinate) + 1)
    }
  }
  //console.log("new current letter:" + currentLetterID.value);

  /* Completed typing the passage!*/
  if (
    parseInt(
      currentLetterID.value.substring(
        1,
        currentTokenizedLetters.value.length - 1
      )
    ) ==
    currentTokenizedLetters.value.length - 1
  ) {
    stopTimer()
    completedExtract.value = true
    //console.log("Completed the passage!");
    return
  }
})
</script>

<style>
.typing-game-intro-message {
  margin-top: 15px;
  text-align: center;
  margin-left: 25px;
  margin-right: 25px;
  line-height: 2;
  position: relative;
  margin-bottom: 15px;
}
.typing-game-window {
  margin-left: 25px;
  margin-right: 25px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #ffffff;
  overflow: hidden;
  text-align: left !important;
}
.base-letter-format {
  display: inline-block;
  line-height: 1;
  font-family: Consolas, monaco, monospace;
  font-size: 26px;
  margin-top: 4px;
  margin-bottom: 4px;
}
.word-container {
  white-space: nowrap;
  display: inline-block;
}
@keyframes fadeIn {
  from {
    text-decoration: underline solid transparent;
  }
}
.active-letter {
  color: rgb(96, 52, 255);
  font-weight: bold;
  text-decoration: underline solid rgb(96, 52, 255);
  animation: fadeIn 0.75s infinite alternate;
}
.letter-correct {
  color: rgb(22, 87, 50);
  background-color: rgb(181, 255, 172);
}
.letter-incorrect {
  color: rgb(96, 52, 255);
  background-color: rgb(255, 163, 182);
}
.letter-retyped {
  color: rgb(22, 87, 50);
  background-color: rgb(255, 246, 163);
}
@keyframes fadeIn-game-complete {
  from {
    opacity: 0;
  }
}
.typing-game-selections {
  position: relative;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
}
.typing-game-completed {
  animation: fadeIn-game-complete 0.5s linear;
  opacity: 1;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
}

.typing-game-button {
  background-color: #f9f9f9;
  border-radius: 6px;
  border: 1px solid #dcdcdc;
  display: inline-block;
  cursor: pointer;
  color: #666666;
  font-family: Arial;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  text-shadow: 0px 1px 0px #ffffff;
}
.typing-game-button:hover {
  background: linear-gradient(to bottom, #e9e9e9 5%, #f9f9f9 100%);
  background-color: #e9e9e9;
}
.typing-game-button:active {
  position: relative;
  top: 1px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/***** Typing Game Settings Bar****/
.typing-game-settings-bar {
  position: relative;
  background-color: #e7eaed;
  margin-left: 25px;
  margin-right: 25px;
  text-align: center;
}

.typing-game-wpm {
  position: relative;
}

/***** Typing Game Settings button icon****/
.typing-game-settings-button {
  position: absolute;
  top: 2px;
  right: 2px;
}
.typing-game-settings-icon {
  width: 20px;
}
.typing-game-settings-menu {
  z-index: 30;
  position: absolute;
  top: 36px;
  right: 20px;
}
</style>
